<template>
    <div class="main">
        <Header :back="true" :title="$t('signup.title')"/>
        <div class="content flex column align-center">
            <p v-html="$t('signup.subtitle',{no: $route.query.numero})"></p>
            <form @submit.prevent="signup" class="flex column align-center">
            <input class="input" type="text" :placeholder="$t('signup.name')" v-model="input.name">
            <input class="input" type="text" :placeholder="$t('signup.surname')" v-model="input.surname">
            <input class="input" type="text" :placeholder="$t('signup.email')" v-model="input.emailAddress">
            <input class="input" type="password" :placeholder="$t('signup.password')"  v-model="input.password">
            <input class="input" type="password" :placeholder="$t('signup.confirmPassword')"  v-model="input.confirmPassword">
            <label style="align-self: flex-start;" class="mini-label">
                <input type="checkbox" v-model="input.terms">
                <a href="/UDUM-Terms-and-conditions-v.1.00.pdf" target="_blank">
                    {{$t('signup.terms')}}
                </a>
            </label>
            <div class="error" v-if="error">{{$t("errors."+error)}}</div>
            <feather type="loader" v-if="loading" animation="spin"/>
            <button class="btn btn-forward" v-else>
                <feather type="arrow-right"/>
            </button>
        </form>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue';

export default {
    components: {
        Header
    },
    data(){
        return{
            input: {
                name: '',
                emailAddress: '',
                password: '',
                confirmPassword: '',
                terms: false,
                activationKey: this.$route.query.codice
            },
            error: '',
            loading: false
        }
    },
    methods: {
        async signup(){
            if(this.loading)return;
            try{
                this.loading = true;
                await this.$store.dispatch("signup",this.input);
                await this.$store.dispatch("login",{username:this.input.emailAddress,password:this.input.password});
                if(this.$store.state.localData.toAfterLogin && this.$store.state.localData.toAfterLogin !== "/"){
                    this.$router.replace(this.$store.state.localData.toAfterLogin);
                    this.$store.commit("setToAfterLogin","/");
                } else this.$router.push("/sound")
                this.loading = false;
            } catch(e){
                //console.error(e);
                let err = await e.response.json();
                this.error = err.error;
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .back{
        position: absolute;
        top: 2em;
        left: 1em;
        .btn {
            width: 36px;
            height: 36px
        }
    }
    .content{
        width: 100%;
        text-align: center;
        margin: 1em auto 0;
        padding-top: 5em;
        padding-bottom: 2em;
    }

    input.input{
        width: 100%;
    }

    form{
        width: 100%;
    }

    .btn-forward{
        margin-top: 2em;
    }
</style>
