<template>
  <div class="body" :class="{ fullscreen: fullscreen }">
    <Header title="Itinerari" :back="'/places?noDisclaimer=true'" />
    <!--h1 class="text-center" style="margin:2em auto">Itinerari</h1-->
        <div id="sources">
          <TabsPage :tabs="[{id: 'nonScoperti',name: 'Da Scoprire'},{id: 'esclusivi',name:'Offline'},{id:'scoperti',name: 'Scoperti'}]">

          <template v-slot:scoperti>
            <div class="cards">
              <p v-if="!$store.getters.discoveredTours || $store.getters.discoveredTours.length == 0">
                Qui troverai gli itinerari che hai percorso e di cui hai già sbloccato i Contenuti Speciali.<br>
                Attivali quando vuoi, per rivivere le esperienze vissute lungo le fonti della Val di Sole.
              </p>
              <CardFonte :fonte="f" v-for="f in $store.getters.discoveredTours" :key="f.id" />
            </div>
          </template>
          <template v-slot:esclusivi>
            <p>
                Qui troverai gli Itinerari in cui la connessione ad un mondo virtuale non può esistere. <br>
                Qui, lasciati emozionare dalla bellezza della natura e dai spazio al tuo tempo.
            </p>
            <div class="cards">
              <CardFonte :fonte="f" v-for="f in $store.getters.autodiscoveredTours" :key="f.id" />
            </div>
          </template>
          <template v-slot:nonScoperti>
            <div class="cards">
              <CardFonte :fonte="f" v-for="f in $store.getters.undiscoveredTours" :key="f.id" />
            </div>
          </template>
        </TabsPage>
        <button  v-if="fullscreen" class="btn btn-large btn-back" @click="$router.go(-1)"><feather type="arrow-left"/></button>
        </div>
  </div>
</template>

<script>
//import GoogleMapsApiLoader from 'google-maps-api-loader'
// eslint-disable-next-line no-unused-vars
import CardFonte from '@/components/CardFonte'
import Header from '../components/Header';
import Tab from '../components/Tab';
import TabsPage from '../components/TabsPage';

export default {

    data(){
        return {
            map: null,
            leaflet: null,
            mapsApi: null,
            fonteCliccata: null,
            markers: [],
            currentMarker: null,
            geoID: null,
            poiDetails: null,
            oamaps: null
        }
    },
    computed: {
        fullscreen(){
            return this.$route.query.fullscreen === true || this.$route.query.fullscreen === 'true';
        }
    },
    methods: {
      async openPOI(id){
          this.$router.push("/sources/details/"+this.$store.state.places.find(x => x.outdoorActiveId == id).id)
      }
    },
    created(){
      //this.$store.dispatch("startPositionWatch");
    },
    components: {
      CardFonte,
      Header,
      // eslint-disable-next-line vue/no-unused-components
      Tab,
      TabsPage
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.body {
  padding-top: 130px!important;
  padding-bottom: 30px!important;
  box-sizing: border-box;
  position: relative;
}
#map {
  width: 100%;
  height: 220px; // NO VH

}

#sources {
  padding-top: 1.6em;
}

.map-container {
  margin: 1em auto;
  width: 90vw;
  position: relative;
  background-color: #28719D;
  box-sizing: border-box;
  border-radius: 20px;
  height: 220px;
  overflow: hidden;
}

.debug-position {
  position: absolute;
  top: 0;
  background-color: red;
}

.position-info {
  padding-left: 1em;
  padding-bottom: 1em;
}
.your-position {
  opacity: 0.75;
  font-size: 0.95em;
}

.fullscreen {
  .body {
    padding: 0;
    margin: 0;
  }
  .map-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    //height: -webkit-fill-available;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
  }
  #map {
    height: 100vh;
    //height: -webkit-fill-available;
  }
  .card-fonte-fullscreen {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 90%;
    margin: 0 auto;
  }

  .btn-back {
    position: absolute;
    top: 2em;
    left: 1em;
    border-color: $bg-color;
    color: $bg-color;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

</style>
