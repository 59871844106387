<template>
   <div class="popup-backdrop" v-if="data.show">
        <div class="popup flex column align-center justify-center">
            <h1>{{data.title}}</h1>
            <p>{{data.body}}</p>
                <button class="btn btn-lg" :class="{'btn-link':!a.primary}" style="margin: 4px;" v-for="(a,index) in data.actions" :key="index" @click="handleAction(a)">
                    {{a.text}}
                </button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            data: {show: false}
        }
    },
    created(){
        this.$root.$on("popup",(d)=>{
            this.data = d;
        })
    },
    methods: {
        async handleAction(action){
            await action.onPressed();
        }
    }
}
</script>

<style lang="scss" scoped>
    .popup-backdrop {
        position: absolute;
        background-color: rgba(82,173,195, .95);
        -webkit-backdrop-filter: blur(20px);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        z-index: 1000;
        display: grid;
        place-items: center;
    }

    .popup {
        .btn {
            width: 100%
        }
    }


</style>
