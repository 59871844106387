<template>
    <div class="teaser-container flex align-center justify-center" :class="{'fullscreen':fullscreen}">
        <div class="flex column align-center" style="height:100%;width:100%">
            <div class="video-container" :class="{'fullscreen':fullscreen}">
                <video :poster="poster ? poster : ''" src="" class="video" ref="video"  :controls="!fullscreen" playsinline></video>
            </div>
            <button @click="$emit('hide')" class="btn btn-large skip-button" v-if="canHide && showSkipButton">{{$t('welcome.skip')}}</button>
        </div>
        <div v-show="!playing" id="play_btn" class="btn btn-forward" @click="play"><feather style="transform:translatex(1px)" type="play"/></div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
    props: ["url","canHide",'fullscreen', 'poster'],
    data(){
        return {
            playing: false,
            showSkipButton: false,
            hls: null
        }
    },
    mounted(){
        if(Hls.isSupported()){
            this.hls = new Hls();
            this.hls.loadSource(this.url);
            this.hls.attachMedia(this.$refs.video);
        } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
            this.$refs.video.src = this.url;
        }
        if(this.canHide){
            this.$refs.video.addEventListener("play",()=>{
                this.$refs.video.addEventListener("ended",() => this.$emit('hide'));
                this.$refs.video.addEventListener("waiting",() => this.showSkipButton = true);
            })
        }
    },
    unmounted(){
        if(this.hls)this.hls.destroy();
    },
    methods: {
        play(){
            this.playing = true;
            this.$refs.video.play();
            if(this.canHide){
                // setTimeout(()=>{this.showSkipButton=true},3000);
                this.showSkipButton = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
    .teaser-container {
        background-color: black;
        max-height: 300px;
        width: 100vw;
        position: relative;
        margin-left: -1em;
    }
    .teaser-container.fullscreen{
        position: fixed;
        top: 0;
        margin-left: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
        opacity: 1;
    }

    #play_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .video-container {
        position: relative;
        overflow: hidden;
        max-height: 300px;
        // margin-left: -1em;
        &.fullscreen {
            width: 100%;
            height: 100%;
            max-height: 100vh;
            overflow: hidden;
            margin-left: 0;
           .video {
            width: 100%;
            height: 100%;
            max-height: 100vh;
            object-fit: cover;
           }
        }
        .video {
            max-height: 300px;
            width: 100%;
            object-fit: cover;
        }
    }

    .skip-button{
        margin: 2em 0;
        text-decoration: underline;
        padding: 1.5em 4em;
        position: absolute;
        background: none;
        // -webkit-backdrop-filter: blur(10px);
        color: $text-primary;
        bottom: calc(env(safe-area-inset-bottom) + 4em);
    }
</style>
