<template>
    <transition name="fade">
    <div class="sidebar" v-if="$root.sidebarOpened">
        <img @click="changeRoute('/sound')" src="/img/logo.svg" id="logo">
        <div class="icons">
            <div class="icon" :class="{'active': $route.path === '/sound'}"  @click="changeRoute('/sound')">
                <img src="/img/menu/sound.svg">
                <span>Ascolta la tua Nota</span>
            </div>
            <div class="icon" :class="{'active': $route.path.indexOf('/places') == 0 || $route.path.indexOf('/fontane') == 0 || $route.path.indexOf('/itinerari') == 0}"  @click="changeRoute('/places')">
                <img src="/img/menu/fonte.svg">
                <span>Trova la tua Fonte</span>
            </div>
             <div @click="changeRoute('/project')" class="icon">
                <img src="/img/menu/project.svg">
                <span>Il progetto</span>
            </div>
            <a href="https://www.facebook.com/unodiunmilione/events" target="_blank" class="icon">
                <img src="/img/menu/events.svg">
                <span>Eventi</span>
            </a>
            <div class="icon" @click="changeRoute('/labs')">
                <img src="/img/menu/labs.svg">
                <span>Laboratori</span>
            </div>
            <div class="icon" :class="{'active': $route.path === '/mappa'}"  @click="changeRoute('/mappa')">
                <img src="/img/menu/map.svg">
                <span>Mappa integrale acqua</span>
            </div>
            <a href="https://www.instagram.com/unodiunmilione_official/" target="_blank" class="icon">
                <img src="/img/menu/insta.svg">
                <span>Instagram</span>
            </a>
        </div>

        <div class="links">
            <a @click="changeRoute('/partners')">I nostri partner</a>
            <a href="https://www.visitvaldisole.it/" target="_blank">Scopri la Val di Sole</a>
            <a href="#" @click="logout">Logout</a>
            <a href="https://popack.org" target="_blank" style="font-weight: 400"><small>© {{year}} Popack srl</small></a>
        </div>
    </div>
    </transition>
</template>

<script>
export default {

    data() {
        return {
            year: new Date().getFullYear()
        }
    },

    methods: {
        changeRoute(r) {
            this.$router.push(r);
            setTimeout(() => {
                this.$root.sidebarOpened = false
            }, 0);


        },
        async logout(){
            // await this.$store.dispatch("logout");
            // this.$root.sidebarOpened = false
            this.$nextTick(()=>{
                this.$root.$emit("popup",{
                    show: true,
                    title: "Sei sicuro?",
                    body: "Sei sicuro di voler effettuare il logout?",
                    actions: [
                        {
                            text: "Si",
                            primary: true,
                            onPressed: async () => {
                                await this.$store.dispatch("logout")
                                this.$root.$emit("popup",{show: false});
                                this.$nextTick(()=>{
                                    this.$root.sidebarOpened = false;
                                    this.$router.push("/welcome");
                                });
                            }
                        },
                        {
                            text: "No",
                            primary: false,
                            onPressed: () => {
                                this.$root.$emit("popup",{show: false});
                            }
                        }
                    ]
                })
            })
        }
    }

}
</script>

<style lang="scss" scoped>
    .sidebar{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        user-select: none;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        background-color: #083946;
        background-image: linear-gradient(45deg, #119C96, #1A83A1);
        z-index: 10;
        box-sizing: border-box;
        padding: 2em;
        padding-right: 30%;
        position: absolute;
        top:0;
        left: 0;
        z-index: 0;
        font-weight: 600;
        font-size: .9em;
        transform-origin: center left;
        a {
            text-decoration: none;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        padding: 1em;
        cursor: pointer;
        width: 100%;
        i{
            opacity: 0.5;
            padding-bottom: 0.25em;

        }
        img {
            opacity: .5;
            object-fit: contain;
            width:1.5em;
            height: 1.5em;
            object-position: center;
        }
        span {
            margin-left: 1em;
            font-weight: 600;
            opacity: 0.5;
        }
        &.active{
            span,img{opacity: 1;}
        }
    }

    #logo {
        margin-top: 2em ;
        object-fit: contain;
        max-height: 64px;
        width: 100%;
    }


    .icons{
        width: 100%;
    }
    .links {
        a {
            display: block;
            margin: 1em;
            text-decoration: none;
        }
    }

    .fade-enter-active, .fade-leave-active {
  transition: opacity .5s cubic-bezier(0.19, 1, 0.22, 1);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  //transform: translatex(-10%);
}
</style>
