<template>
    <div class="tab-container">
        <div class="tab-header">
            <div class="tab-header-item" :class="{'selected': activeTab.id === tab.id}" v-for="tab in tabs" :key="tab.id" @click="activeTab = tab">{{tab.name || tab.id}}</div>
        </div>
        <div class="tabs">
            <div v-for="tab in tabs" class="tab" :key="tab.id" >
                <slot :name="tab.id" v-if="activeTab.id === tab.id"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["tabs"],
    data(){
        return {
            activeTab: this.tabs[0]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/style/variables.scss";
    .tab-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow-x: scroll;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: fixed;
        top: 106px;
        background: transparentize($color: #368ca4, $amount: 0.1);
        width: 100vw;
        // margin-left: -1em;
        left: 0;
        z-index: 9;
        padding: 0 1em;
        font-size: 14px;
        padding-bottom: 0;
        -webkit-backdrop-filter: blur(20px) saturate(1.2);
        box-sizing: border-box;
    }

    .tab-header-item {
        padding: 8px;
        &:active {
            background-color: $color-active;
        }
        &.selected {
            border-bottom: 4px solid white;
        }
    }
</style>
