import ky from 'ky';
let promise = null;
import store from './store'
async function refreshSessione() {
    if (promise) await promise;
    else {
        promise = (async () => {
            const res = await ky.post('./api/Session/Refresh').json();
            if (res.status) {
                store.commit("setSession",res.session);
                promise = null;
            } else throw Error();
        })();
        await promise;
    }
}

export default ky.create({
    hooks: {
        beforeRequest: [
            async (r) => {
                var cookies = document.cookie.split(";");
                if (!cookies.some(x => x.indexOf("udum_session_token=") !== -1) && new URL(r.url).pathname !== "/api/Session/Refresh") {
                    await refreshSessione();
                }
            },
        ],
    },
}, );
