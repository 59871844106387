export default {
    it: {
        welcome: {
            skip: 'Salta',
            title: 'Benvenuto',
            subtitle: 'Attiva la borraccia Uno Di Un Milione e svela la tua nota. Scopri l’esperienza sonora e immergiti lungo le fonti e i percorsi dell’acqua della Val di Sole.',
            //subtitle2: 'Scopri l’esperienza sonora e immergiti lungo le fonti e i percorsi dell’acqua della Val Di Sole.',
            btnSignup: 'Attiva la borraccia',
            btnLogin: 'Effettua il login'
        },
        login: {
            title: 'Accedi',
            subtitle: 'Accedi con indirizzo Email e Password',
            email: 'Indirizzo Email',
            password : 'Password',
            forgot: 'Password Dimenticata?'
        },
        verify: {
            title: 'Inserisci il codice',
            subtitle: 'Trovi il codice di sblocco nella card Uno Di Un Milione all’interno della confezione.',
            code: 'Codice'
        },
        season: {
            'jan': "Gennaio",
            'feb': "Febbraio",
            "mar": "Marzo",
            "apr": "Aprile",
            "may": "Maggio",
            "jun": "Giugno",
            "jul": "Luglio",
            "aug": "Agosto",
            "sep": "Settembre",
            "oct": "Ottobre",
            "nov": "Novembre",
            "dec": "Dicembre"
        },
        signup: {
            title: 'Registrati',
            subtitle: 'Sei l\'<b>utente numero {no}</b> che ha attivato la borraccia.<br><br>Aggiungi username per <b>dare il nome alla tua nota</b>. Inserisci la tua e-mail e una password per continuare',
            name: 'Nome',
            surname: 'Cognome',
            email: 'Indirizzo Email',
            password: 'Password',
            confirmPassword: 'Conferma Password',
            terms: 'Accetto i termini e le condizioni d\'uso'
        },
        sound: {
            title_my: 'HAI SBLOCCATO L\'ULTIMO SUONO',
            title_oth: 'ULTIMO SUONO SVELATO',
            name: 'Nota di {name}',
            yours: 'Il tuo suono',
            play_latest: 'Riproduci tutto il brano',
            play_my: 'Riproduci la tua nota',
            percentage: 'Brano svelato al',
            listenNote: 'Ascolta la tua NOTA',
            listenTrack: 'Ascolta il BRANO',
        },
        errors: {
            password_too_short: 'Password troppo corta',
            password_mismatch: "La verifica password non è valida.",
            terms_not_applied: "Accetta i termini e le condizioni d'uso per continuare.",
            email_already_exists: 'Esiste già un account con questa mail.',
            code_not_found: 'Il codice inserito non è valido.',
            code_already_used: 'Questa borraccia è già stata associata ad un account.',
            EMAIL_EXISTS: 'Esiste già un account con questa mail.',
            passwordMismatch: 'Le due password non corrispondono.',
            passwordInsecure: "Inserisci una password di almeno 8 caratteri.",
            UNACCEPTED_TERMS: "Accetta i termini e le condizioni d'uso per continuare.",
            PASSWORD_MISMATCH: "La verifica password non è valida.",
            INVALID_PASSWORD: "Inserisci una password di almeno 8 caratteri."
        },
        difficulty: {
            easy: "Facile",
            medium: "Medio",
            hard: "Difficile"
        }
    },
    /*en: {
        welcome: {
            skip: 'Skip',
            title: 'Welcome',
            subtitle: 'COPY_BOTTLE_WELCOME_SUBTITLE',
            btnSignup: 'Sign-Up',
            btnLogin: 'Login'
        },
        difficulty: {
            easy: "Easy",
            medium: "Medium",
            hard: "Hard"
        }
    }*/
}
