<template>
    <div class="main">
        <Header :back="true" :transparent="true"/>
         <div class="grid_container">
        <div class="bottle"></div>
        <div class="content flex column align-center">
            <h1>{{$t("verify.title")}}</h1>
            <p>{{$t('verify.subtitle')}}</p>
            <div v-if="error" class="error">{{$t('errors.'+error)}}</div>
            <form @submit.prevent="verify" class="login-form">
                <input type="text" class="input" :placeholder="$t('verify.code')" v-model="codice" required>
                <button class="btn btn-forward">
                    <feather type="arrow-right"/>
                </button>
            </form>
        </div></div>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
export default {
    components: {
        Header
    },
    data(){
        return {
            codice: '',
            error: ''
        }
    },
    methods: {
        async verify(){
            try{
                this.error = '';
                const r = await this.$store.dispatch("getActivationStatus",this.codice);
                if(r.status){
                    this.$router.push({path: '/signup',query: {numero: r.number,codice: this.codice}})
                }
            } catch(e){
                var r = await e.response.json();
                this.error = r.error;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .content{
        text-align: center;
        padding-bottom: 2em;
    }

    .back{
        position: absolute;
        left: 1em;
        top: 2em;
        .btn {
            width: 36px;
            height: 36px
        }
    }

    .btn-forward{
        margin-top: 2em;
    }

    .login-form {
        text-align: center;
        width: 100%;
        & > * {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .bottle{
        margin: 0 auto;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        background:url(/img/bottle-code.png) bottom center no-repeat;
        background-size: contain;
    }



  .grid_container {
    display: grid;
    box-sizing: border-box;
    align-items: center;
    grid-template-rows: 1fr 1.3fr;
    // height: 100%;
  }


</style>
