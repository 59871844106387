<template>
  <div>
    <div v-if="difficulty" class="badge" :class="'diff-badge ' + difficulty">
      {{ $t("difficulty." + difficulty) }}
    </div>
    <TabsPage :tabs="tabs">
      <!--Mappa-->
      <template v-slot:map>
        <Map
          :id="poiDetails.id"
          class="map"
          :height="poiDetails.frontendtype === 'poi' ? 300 : 600"
        />
      </template>
      <!--Sintesi-->
      <template v-slot:summary>
        <img
          v-if="poiDetails.primaryImage"
          :src="'https://img.oastatic.com/img/' + poiDetails.primaryImage.id + '/.jpg'"
          class="primary-image"
        />
        <div v-html="poiDetails.shortText"></div>
        <div v-html="poiDetails.longText"></div>
        <template v-if="poiDetails.tip">
          <h3>Consiglio dell'autore</h3>
          <div v-html="poiDetails.tip"></div>
        </template>
      </template>
      <!-- Dettagli -->
      <template v-slot:details v-if="poiDetails.frontendtype === 'tour'">
        <template v-if="poiDetails.rating">
          <br />
          <span class="label">Valutazioni</span>
          <div class="valutazioni">
            <div class="card" v-if="poiDetails.rating && poiDetails.rating.diffculty">
              <h1>{{ poiDetails.rating.diffculty }}<small>/6</small></h1>
              <span>Difficoltà</span>
            </div>
            <div class="card" v-if="poiDetails.rating && poiDetails.rating.condition">
              <h1>{{ poiDetails.rating.condition }}<small>/6</small></h1>
              <span>Impegno Fisico</span>
            </div>
            <div class="card" v-if="poiDetails.rating && poiDetails.rating.qualityOfExperience">
              <h1>{{ poiDetails.rating.qualityOfExperience }}<small>/6</small></h1>
              <span>Emozione</span>
            </div>
            <div class="card" v-if="poiDetails.rating && poiDetails.rating.landscape">
              <h1>{{ poiDetails.rating.landscape }}<small>/6</small></h1>
              <span>Paesaggio</span>
            </div>
          </div>
        </template>
        <template v-if="poiDetails.season">
          <br />
          <span class="label">Stagioni ideali</span>
          <div class="stagioni" v-if="poiDetails.season">
            <span
              class="card"
              v-for="(s, i) in Object.entries(poiDetails.season)
                .filter(([k, v]) => v == true)
                .map(([k, v]) => k)"
              :key="i"
              >{{ $t("season." + s) }}</span
            >
          </div>
        </template>
        <span
          v-if="poiDetails.crestDescription || poiDetails.elevation || poiDetails.groundDescription"
          class="label"
          >Altri dettagli</span
        >
        <div class="table">
          <div class="tr" v-if="poiDetails.crestDescription && poiDetails.elevation.maxAltitude">
            <div>Punto più alto</div>
            <div>
              {{ poiDetails.crestDescription }} &bull;
              {{ poiDetails.elevation.maxAltitude.toFixed(0) }} m
            </div>
          </div>
          <div class="tr" v-if="poiDetails.groundDescription && poiDetails.elevation.minAltitude">
            <div>Punto più basso</div>
            <div>
              {{ poiDetails.groundDescription }} &bull;
              {{ poiDetails.elevation.minAltitude.toFixed(0) }} m
            </div>
          </div>
          <div class="tr" v-if="poiDetails.rating && poiDetails.rating.diffculty">
            <div>Difficoltà</div>
            <div>{{ poiDetails.rating.difficulty }}</div>
          </div>
          <div class="tr" v-if="poiDetails.length">
            <div>Lunghezza</div>
            <div>{{ (poiDetails.length / 1000).toFixed(0) }} km</div>
          </div>
          <div class="tr" v-if="poiDetails.time">
            <div>Durata</div>
            <div>
              {{ timeConvert(poiDetails.time.min) }} <template v-if="poiDetails.time.max">
                - {{ timeConvert(poiDetails.time.max) }} </template
              >
            </div>
          </div>
          <div class="tr" v-if="poiDetails.elevation && poiDetails.elevation.ascent">
            <div>Ascesa</div>
            <div>{{ poiDetails.elevation.ascent }} m</div>
          </div>
          <div class="tr" v-if="poiDetails.elevation && poiDetails.elevation.descent">
            <div>Discesa</div>
            <div>{{ poiDetails.elevation.descent }} m</div>
          </div>
        </div>
        <template v-if="poiDetails.properties">
          <br />
          <span class="label">Caratteristiche</span>
          <div class="properties" v-if="poiDetails.properties">
            <span class="card" v-for="p in poiDetails.properties.property" :key="p.tag">
              {{ p.text }}
            </span>
          </div>
        </template>
      </template>
      <!--Direzioni -->
      <template v-slot:directions>
        <template v-if="poiDetails.startingPoint">
          <p class="label">Partenza</p>
          <div>
            {{ poiDetails.startingPointDescr }}<br /><br />
            Latitudine: {{ poiDetails.startingPoint.lat }} Longitudine:
            {{ poiDetails.startingPoint.lon }}
          </div>
        </template>
        <template v-if="poiDetails.destination">
          <p class="label">Arrivo</p>
          <div>{{ poiDetails.destination }}</div>
        </template>
        <template v-if="poiDetails.directions && poiDetails.directions.length > 0">
          <p class="label">Direzioni da Seguire</p>
          <div v-html="poiDetails.directions"></div>
        </template>
      </template>
      <!-- Sicurezza -->
      <template v-slot:security>
        <p class="label">Indicazioni sulla sicurezza</p>
        <div v-html="poiDetails.safetyGuidelines"></div>
      </template>
      <!-- Arrivare -->
      <template v-slot:getting-there>
        <template v-if="poiDetails.address">
          <p class="label">Indirizzo</p>
          <p>
            {{ poiDetails.address.street }} {{ poiDetails.address.houseNumber }},&nbsp;{{
              poiDetails.address.zipcode
            }}
            {{ poiDetails.address.town }}
          </p>
        </template>
        <template v-if="poiDetails.gettingThere">
          <p class="label">Come arrivare</p>
          <div v-html="poiDetails.gettingThere"></div>
        </template>
        <template v-if="poiDetails.parking">
          <p class="label">Parcheggio</p>
          <div v-html="poiDetails.parking"></div>
        </template>
        <template v-if="poiDetails.publicTransit">
          <p class="label">Con i mezzi pubblici</p>
          <div v-html="poiDetails.publicTransit"></div>
        </template>
      </template>
      <!-- Contenuto -->
      <template v-slot:content>
        <component :is="componentData" />
      </template>
    </TabsPage>
  </div>
</template>

<script>
import Vue from "vue";
import TabsPage from "./TabsPage";
import Map from "./Map";
export default {
  props: ["place"],
  data() {
    return {
      componentData: null
    };
  },
  computed: {
    difficulty() {
      let data =
        this.place.outdoorActiveData[this.$i18n.locale] || this.place.outdoorActiveData["en"];
      if (!data || !data.rating) return null;
      let diff = data.rating.difficulty;
      if (!diff) return null;
      return ["unknown", "easy", "medium", "hard"][diff];
    },
    poiDetails() {
      return this.place.outdoorActiveData[this.$i18n.locale] || this.place.outdoorActiveData.en;
    },
    tabs() {
      let tabs = [];
      if (this.place.discoverData) tabs.push({ id: "content", name: "Contenuti" });
      tabs.push({ id: "map", name: "Mappa" });
      tabs.push({ id: "summary", name: "Descrizione" });
      if (this.poiDetails.frontendtype === "tour") tabs.push({ id: "details", name: "Dettagli" });
      if (this.poiDetails.safetyGuidelines) tabs.push({ id: "security", name: "Sicurezza" });
      if (
        this.poiDetails.destination ||
        this.poiDetails.startingPoint ||
        this.poiDetails.directions
      )
        if (this.place.type === "tour") tabs.push({ id: "directions", name: "Direzioni" });
      if (this.poiDetails.gettingThere || this.poiDetails.parking || this.poiDetails.publicTransit)
        tabs.push({ id: "getting-there", name: "Come arrivare" });
      return tabs;
    }
  },
  methods: {
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);

      let hString = rhours + ' ' + (rhours == 1 ? "ora" : " ore");
      let mString = rminutes + ' ' + (rminutes == 1 ? "minuto." : "minuti.");
      return hString + (rminutes > 0 ? ' e ' + mString : '.');
    }
  },
  created() {
    if (this.place && this.place.discoverData) {
      let dd = this.place.discoverData[this.$i18n.locale] || this.place.discoverData["it"];
      this.componentData = Vue.compile(dd);
    }
  },
  components: {
    TabsPage,
    Map
  }
};
</script>

<style lang="scss">
.source-details .tab-header {
  width: 100vw;
  white-space: nowrap;
  z-index: 9999;
  top: 99px;
  left: 0;
  & > * {
    padding: 8px 1.2em;
  }
}
</style>

<style scoped lang="scss">
@import "@/style/variables.scss";

.poi-details {
  margin-top: -1em;
}

.poi-tab {
  background-color: #468ea4;
  border-bottom: 1px rgba(0, 0, 0, 0.1);
  padding: 1em 0.5em;
  cursor: pointer;
  position: sticky;
  z-index: 999999;
  top: 60px;
  &:hover,
  &.active {
    background-color: lighten($bg-color, 20);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.poi-page {
  padding: 1em 1em;
  box-sizing: border-box;
  background: #0f9bc2c4;
  text-align: justify;
}

.primary-image {
  width: 100vw;
  height: 350px;
  margin-left: -1em;
  -o-object-fit: cover;
  object-fit: cover;
}

.page-header {
  position: relative;
  z-index: 1;
  margin-top: -6em;
  h1 {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:before {
    content: " ";
    position: absolute;
    left: -1em;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #0008, transparent);
  }
}

h2#place-name {
  font-size: 1em;
  margin: 2em auto;
  opacity: 0.8;
}

.poi-tabs {
  margin-left: -1em;
  margin-right: -1em;
}

.valutazioni {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 1em auto;
  grid-gap: 0.5em;
  .card {
    margin: 0;
    width: 100%;
    span {
      font-size: 0.8em;
    }
    small {
      font-size: 0.6em;
    }
  }
}
.card {
  background-color: rgba(82, 173, 195, 0.8);
  border-radius: 8px;
  margin: 1em auto;
  padding: 1em;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  h1 {
    margin: 0;
  }
}

.stagioni,
.properties {
  margin: 1em auto;

  .card {
    padding: 0.25em 0.4em;
    text-align: center;
    font-size: 14px;
    margin: 0.2em;
    display: inline-block;
  }
}

.diff-badge {
  padding: 4px 8px;
  position: fixed;
  font-size: 12px;
  top: 54px;
  right: 1em;
  border-radius: 8px;
  z-index: 9999;
  &.easy {
    background-color: #0066cc;
  }
  &.medium {
    background-color: #cc0000;
  }
  &.hard {
    background-color: #000000;
  }
}

.table {
  .tr {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-gap: 1.5em;
    div:first-child {
      font-weight: 600;
    }
  }
}
</style>
