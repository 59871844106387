<template>
  <div class="container">
    <Header/>
    <div class="text-center">
      <img src="/img/logo.svg" id="logo">
      <h1>Coming soon</h1>
      <p>Questo contenuto arriverà a breve.</p>
      <br>
      <a @click="$root.sidebarOpened = true" class="btn">Torna indietro</a>
    </div>

  </div>
</template>
<script>
import Header from '../components/Header'
export default {
  components: {
    Header
  }
}
</script>
<style lang="scss" scoped>

  .container {
    display: grid;
    place-items: center;
    height:100%;
  }

</style>
