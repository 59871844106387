<template>
    <div class="flex column">
        <Header />
        <div class="map-container">
            <div id="map"></div>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header';
export default {
    methods: {
        openPOI(id){
            this.$router.push("/sources/details/"+this.$store.state.places.find(x => x.outdoorActiveId == id).id)
        }
    },
    components: {
        Header
    },
    async mounted(){
        window.oa.api.maps(async (oamaps, gm)  => {
            // set map center, zoom level, map types and more
            this.oamaps = oamaps;

            // instatiate a new outdooractive map
            // params: dom id of map canvas div, configuration object
            this.leaflet = gm;
            let pois = this.$store.state.places.filter(x => x.outdoorActiveId).map(x => x.outdoorActiveId);
            let clusterLayer = new oamaps.GeomLayer(pois,{
                fitPoiBounds  : true,
                fitTourBounds : true,
                markersActive : true,
            });
            clusterLayer.listen('markerClick',(m)=> {
                this.openPOI(m.id)
            })
            clusterLayer.whenLoaded(()=>{
                let bounds = clusterLayer.getBounds();
                let config = {
                    bounds,
                    mapInit : {
                        basemap: "oac",
                        style:   "summer",
                        network: "hiking",
                    }

                };

                this.map = oamaps.map( "map", config );
                clusterLayer.setMap(this.map);
            })
            //this.$store.dispatch("startPositionWatch");
        })
    },
}
</script>

<style lang="scss" scoped>
    .map-container {
        padding-top: 96px;
    }
    .map-container, #map {
        height: 100%;
        width: 100vw;
        margin-left: -8px;
    }
</style>
