<template>
  <div class="body" :class="{ fullscreen: fullscreen }">
    <Header title="Fontane" :back="'/places?noDisclaimer=true'"/>
    <!--h1 class="text-center" style="margin:2em auto">Fontane</h1-->
        <div id="sources">
          <CardFonte :fonte="f" v-for="f in $store.getters.fountains" :key="f.id" />
        </div>
        <button  v-if="fullscreen" class="btn btn-large btn-back" @click="$router.go(-1)"><feather type="arrow-left"/></button>
  </div>
</template>

<script>
//import GoogleMapsApiLoader from 'google-maps-api-loader'
// eslint-disable-next-line no-unused-vars
import CardFonte from '@/components/CardFonte'
import Header from '../components/Header';

export default {

    data(){
        return {
            map: null,
            leaflet: null,
            mapsApi: null,
            fonteCliccata: null,
            markers: [],
            currentMarker: null,
            geoID: null,
            poiDetails: null,
            oamaps: null
        }
    },
    computed: {
        fullscreen(){
            return this.$route.query.fullscreen === true || this.$route.query.fullscreen === 'true';
        }
    },
    methods: {
      async openPOI(id){
          this.$router.push("/sources/details/"+this.$store.state.places.find(x => x.outdoorActiveId == id).id)
      }
    },
    created(){
      //this.$store.dispatch("startPositionWatch");
    },
    components: {
      CardFonte,
      Header
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.body {
  padding-top: 100px!important;
  padding-bottom: 30px!important;
  box-sizing: border-box;
  position: relative;
}
#map {
  width: 100%;
  height: 220px; // NO VH

}

#sources {
  padding-top: 2em;
}

.map-container {
  margin: 1em auto;
  width: 90vw;
  position: relative;
  background-color: #28719D;
  box-sizing: border-box;
  border-radius: 20px;
  height: 220px;
  overflow: hidden;
}

.debug-position {
  position: absolute;
  top: 0;
  background-color: red;
}

.position-info {
  padding-left: 1em;
  padding-bottom: 1em;
}
.your-position {
  opacity: 0.75;
  font-size: 0.95em;
}

.fullscreen {
  .body {
    padding: 0;
    margin: 0;
  }
  .map-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    //height: -webkit-fill-available;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
  }
  #map {
    height: 100vh;
    //height: -webkit-fill-available;
  }
  .card-fonte-fullscreen {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 90%;
    margin: 0 auto;
  }

  .btn-back {
    position: absolute;
    top: 2em;
    left: 1em;
    border-color: $bg-color;
    color: $bg-color;
  }
}
</style>
