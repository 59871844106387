<template>
    <div class="flex column align-center">
        <h1>Password Reset</h1>
        <p>Inserisci la nuova password</p>
        <form @submit.prevent="passwordReset" class="flex column align-center">
        <input type="password" class="input" placeholder="Password" v-model="password">
        <input type="password" class="input" placeholder="Conferma Password" v-model="confirm">
        <div v-if="okay" class="flex column align-center">
            Password cambiata con successo!
            <button class="btn btn-large" @click="$router.push('/login')">Effettua il Login</button>
        </div>
        <div v-else-if="error" class="error">{{error}}</div>
        <div v-else-if="loading">
            <feather type="loader" animation="spin"/>
        </div>
        <button class="btn btn-forward" v-if="!(loading || okay)">
            <feather type="arrow-right"/>
        </button>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            password: null,
            confirm: null,
            okay: false,
            error: null,
            errors: {
                "Password Mismatch": this.$t("errors.passwordMismatch"),
                "Password does not match criteria": this.$t("errors.passwordInsecure"),
            }
        }
    },
    methods: {
        async passwordReset(){
            this.loading = true;
            this.okay = false;
            this.error = null;
            try {
                let a = await this.$store.dispatch("resetPassword",{password: this.password,confirm: this.confirm,token: this.$route.params.token});
                if(a.success){
                    this.okay = true;
                } else {
                    this.error = this.errors[a.error];
                }
            } catch(e){
                this.error = e;
            }
            this.loading = false;
        }
    }
}
</script>
