import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import DiscoverWelcome from '../views/DiscoverWelcome.vue';
import Login from '../views/Login.vue';
import Verify from '../views/Verify.vue'
import Signup from '../views/Signup.vue';
import Sound from '../views/Sound.vue';
import Fontane from '../views/Fontane.vue';
import Itinerari from '../views/Itinerari.vue';
import Discover from '../views/Discover.vue';
import SourceDetails from '../views/SourceDetails.vue';
import ResetPassword from '../views/ResetPasswordRequest.vue';
import ResetPasswordForm from '../views/ResetPasswordForm.vue';
import Places from '../views/Places.vue';
import PuntiInteresse from '../views/PuntiInteresse.vue';
import Mappa from '../views/MappaIntegrale.vue';
import store from '@/store';
import Partners from '../views/Partners'
import Project from '../views/Project'
import ComingSoon from '../views/ComingSoon'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () => {
      return store.state.loggedIn ? "/sound" : "/welcome";
    }
  },
  {
    path: '/welcome',
    name: 'Home',
    component: Index,
    meta: {guestOnly: true}
  },
  {
    path: '/partners',
    name: 'Partner',
    component: Partners
  },
  {
    path: '/project',
    name: "Project",
    component: Project
  },
  {
    path: '/welcome/discover',
    name: 'Home',
    component: DiscoverWelcome,
    meta: {guestOnly: true}
  },
  {
    path: '/login',
    component: Login,
    meta: {guestOnly: true}
  },
  {
    path: '/signup',
    component: Signup,
    meta: {guestOnly: true}
  },
  {
    path: '/verify',
    component: Verify,
    meta: {guestOnly: true}
  },
  {
    path: '/sound',
    component: Sound
  },
  {
    path: '/places',
    component: Places
  },
  {
    path: '/fontane',
    component: Fontane
  },
  {
    path: '/itinerari',
    component: Itinerari
  },
  {
    path: '/poi',
    component: PuntiInteresse
  },
  {
    path: '/mappa',
    component: Mappa
  },
  {
    path: '/labs',
    component: ComingSoon
  },
  {
    path: '/events',
    component: ComingSoon
  },
  {
    path: '/discover/:id',
    component: Discover
  },
  {
    path: '/sources/details/:id',
    component: SourceDetails
  },
  {
    path: '/resetPassword',
    component: ResetPassword
  },
  {
    path: '/resetPassword/:token',
    component: ResetPasswordForm
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to,from,next) => {
  if(to.meta.guestOnly && store.state.loggedIn){
      next('/')
  } else {
    next();
  }
})

export default router;
