<template>
  <div class="container">
    <Header title="Il Progetto"/>

    <iframe class="iframe" src="https://unodiunmilione.com/it/pages/app-progetto"/>
  </div>
</template>
<script>
import Header from '../components/Header'
export default {
  components: {
    Header
  }
}
</script>
<style lang="scss">

  .route-project .router-wrap {
    background: white;
  }

</style>
<style lang="scss" scoped>


  .container {
    padding-top:5em!important;
    box-sizing: border-box;
    height:100%;
    overflow: hidden!important;
  }

  .iframe {
    width: 100vw;
    margin-left: -1em;
    border: none;
    height: 100%;
  }

</style>
