<template>
    <div class="body">
        <Header :transparent="true" title="Trova la tua Fonte"/>
        <div  v-if="!$store.state.localData.ackDiscPlaces && showDisclaimer" class="grid_container disclaimer">
            <div class="bottle"></div>
            <div class="flex column align-center">
                <p class="disclaimer">
                    Scopri <b>Fontane</b>, <b>Itinerari</b> e <b>Mete</b> che hanno ispirato la composizione del brano sinfonico.
                    <br>Lungo i percorsi troverai i QR code Uno Di Un Milione con cui potrai sbloccare i “Contenuti Speciali” che raccontano il processo creativo e svelano i paesaggi sonori appositamente creati.
                </p>
                <br>
                <label style="align-self: flex-start;" class="mini-label">
                    <input type="checkbox" v-model="doNotAskAgain">&nbsp;Non chiedere più
                </label>
                <br>
                <button class="btn btn-forward" @click="next">
                    <feather type="arrow-right"/>
                </button>
            </div>
            </div>
        <div class="content flex column align-center" v-else>
            <div id="card-f" class="card justify-center align-center flex column" @click="$router.push('/fontane')">
                <div class="card-title">FONTANE</div>
            </div>
            <div id="card-i" class="card justify-center align-center flex column" @click="$router.push('/itinerari')">
                <div  class="card-title">ITINERARI</div>
            </div>
            <div id="card-m" class="card justify-center align-center flex column" @click="$router.push('/poi')">
                <div  class="card-title">METE</div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header';

export default {
    components: {
        Header
    },
    data(){
        return {
            showDisclaimer: true,
            doNotAskAgain: false
        }
    },
    created() {
        if(this.$route.query.noDisclaimer) this.showDisclaimer = false
    },
    methods: {
        next(){
            this.showDisclaimer = false;
            if(this.doNotAskAgain){
            this.$store.commit("ackDisclaimerPlaces");
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.body {
  padding-top: 5em!important;
  box-sizing: border-box;
  position: relative;
}

.card {
    height: 150px;
    width: 100%;
    background-position: center;
    background-size: cover;
    margin: 16px;
    border-radius: 8px;
    transition: transform linear 0.0625s;
    text-shadow: 0px 2px 8px rgba(0,0,0,.8);
    &:active {
        transform: scale(0.95);
    }
}


.content {
    text-align: center;
}

#card-f {
    background-image: url('/img/fontane.jpg');
}

#card-i {
    background-image: url('/img/itinerari.jpg');
}

#card-m {
    background-image: url('/img/mete.jpg');
}

.card-title {
    font-size: 32px;
    user-select: none;
}
  .grid_container.disclaimer, .pg_c {
    font-size: 14px;
    display: grid;
    box-sizing: border-box;
    align-items: center;
    grid-template-rows: 2fr 1.5fr;
    min-height: 100%;
    height: 100%;
    padding-bottom: 2em;
      .bottle{
    background: url(/img/bottle-with-gradient.png) center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    max-height: 310px;
    background-position: bottom center;
    margin: 0 auto;
    margin-top: 4em;
    @media screen and (max-height: 560px) {
      display: none;
    }
  }
  }

</style>
