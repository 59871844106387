import Vue from 'vue';
import VueI18n from 'vue-i18n'
import App from './App.vue';
import router from './router';
import CustomVideo from './components/CustomVideo'
import AudioPlayer from './components/AudioPlayer'
import './registerServiceWorker';
// eslint-disable-next-line no-unused-vars
import style from './style/style.scss';
import lang from './lang'
import store from './store'
import VueFeather from 'vue-feather';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.component("udum-video", CustomVideo);
Vue.use(VueFeather);
Vue.component("udum-audio", AudioPlayer);

let locale = (navigator.language || navigator.userLanguage).split("-")[0];
if(["it"].indexOf(locale) === -1)locale = "it";
const i18n = new VueI18n({
  messages: lang,
  locale,
  fallbackLocale: 'it'
})
let ignoredPrefixes = ["#/welcome", "#/discover","#/resetPassword"];
let vue = null;
(async () => {
  await store.dispatch("refreshSession");
  console.log("Session got")
  vue = new Vue({
    router,
    i18n,
    render(h) {
      console.log(this.ready)
      return this.ready ? h(App) : h()
    },
    store,
    async created() {
      try {
        if (store.state.loggedIn) {
          console.log("Initing")
          await this.$store.dispatch("init");
        } else {
          if (!ignoredPrefixes.some(p => document.location.hash.indexOf(p) === 0)) {
            store.commit("setToAfterLogin", document.location.hash.substring(1));
            document.location.replace('/#/welcome');
          }
        }
        this.ready = true
      } catch (e) {
        console.error("Error during dispatch!")
        console.error(e);
        store.commit("setToAfterLogin", document.location.hash.substring(1));
        document.location.replace('/#/welcome');
        this.ready = true
      }
    },
    data() {
      return {
        showTeaser: true,
        mapInit: false,
        detailsSource: null,
        ready: false,
        sidebarOpened: false
      }
    },
    mounted(){
      console.log(this.$i18n.locale);
      document.querySelector("body").setAttribute("lang",this.$i18n.locale);
    }
  }).$mount('#app');
})();

window.initMap = () => {
  vue.mapInit = true
};

export default vue
