<template>
  <div class="main flex column">
    <Header :difficulty="fonte.outdoorActiveData[$i18n.locale].rating && fonte.outdoorActiveData[$i18n.locale].rating.difficulty" :title="fonte.name[$i18n.locale] || fonte.name['it']" :marquee="true" :desc="descr" :back="true"/>

    <div v-if="fonte" class="source-details">
      <div class="poi-details-container" v-if="poiDetails">
          <OOIDetails :poiDetails="poiDetails" :place="fonte"/>
      </div>
    </div>
  </div>
</template>




<script>
import OOIDetails from '@/components/OOIDetails'
import Header from '../components/Header.vue';
export default {
  components: {OOIDetails, Header},
  computed: {
    fonte() {
      return this.$store.state.places.find(
        (x) => x.id === this.$route.params.id
      );
    },
    poiDetails(){
      return this.fonte.outdoorActiveData[this.$i18n.locale] || this.fonte.outdoorActiveData.en;
    },
    descr() {
      let descr = this.fonte.description;
      if(descr && descr[this.$i18n.locale])return descr[this.$i18n.locale];
      let oData = this.fonte.outdoorActiveData[this.$i18n.locale];
      if(oData.address && oData.address.town)return oData.address.town;
      if(oData.friendly_location)return oData.friendly_location;
      return "";
    },
  },
};
</script>

<style lang="scss">
.btn-back {
  display: block;
}

.main {
  .topbar {
    padding:.8em!important
  }
  .source-details {
    padding-top: 140px;
  }
}


.html-content {
  a {
    color: white;
  }
  img {
    max-width: 100%;
  }
}
</style>
