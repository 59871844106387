<template>
  <div class="content flex align-end" style="padding: 0;">
    <div class="left flex column align-start" style="flex-grow: 1">
      <h2>{{ title || 'Contenuto Speciale' }}</h2>
      <div
        class="progress-container"
        :class="{ playing: playing || started }"
        ref="progressContainer"
      >
        <div class="progress"></div>
        <div
          class="progress-total"
          :style="{
            width: percentage + '%',
            transition: transition('width'),
          }"
        ></div>
        <div
          class="progress-my"
          :style="progressBar"
        ></div>
        <div
          class="progress-notch"
          :style="{
            left: 'calc(' + notchPosition + '%' + ' - 20px)',
            transition: transition('left'),
          }"
          @pointerdown="onNotchPressed"
        ></div>
      </div>
      <div v-if="percentage" style="position: relative;width: 100%;">
        <div
          :style="{
            'left': 'calc(' +percentage + '% - 18px)',
            'padding-top': '12px',
            'position': 'absolute'
          }"
        >
          {{ percentage.toFixed() }}%
        </div>
      </div>
    </div>
    <div class="flex column align-center justify-center right">
      <div class="btn btn-forward" @click="play">
        <i class="fas fa-circle-notch fa-spin" v-if="buffering"></i>
        <i
          class="fas"
          v-else
          :class="{ 'fa-play': !playing, 'fa-pause': playing }"
        ></i>
      </div>
      <span v-if="cta" class="player-cta">{{cta}}</span>
    </div>
    <audio ref="audio" preload="none" :src="url"></audio>
  </div>
</template>

<script>
export default {
  props: ["url", "title", "percentage","cta"],

  data() {
    return {
      playing: false,
      started: false,
      buffering: true,
      seeking: false,
      currentTime: 0,
      notchPosition: 0,
    };
  },
  async mounted() {
    this.$refs.audio.addEventListener("play", () => {
      this.playing = true;
      this.started = true;
    });
    this.$refs.audio.addEventListener("pause", () => {
      this.playing = false;
    });
    this.$refs.audio.addEventListener("ended", () => {
      this.playing = false;
      this.started = false;
      this.$refs.audio.load();
    });
    this.$refs.audio.addEventListener("canplay", () => {
      this.buffering = false;
    });
    this.$refs.audio.addEventListener("waiting", () => {
      this.buffering = true;
    });
    this.$refs.audio.addEventListener("timeupdate", () => {
      if (!this.$refs.audio.currentTime) this.currentTime = 0;
      else
        setTimeout(
          () =>
            (this.currentTime =
              (this.$refs.audio.currentTime / this.$refs.audio.duration) * 100),
          300
        );
      if (!this.seeking) {
        this.notchPosition = this.currentTime.toFixed(2);
      }
    });
    this.$refs.audio.load();
    this.$root.$on("pointerMove", (e) => {
      if (!this.seeking) return;
      let cont = this.$refs.progressContainer.getBoundingClientRect();
      let v = ((e.clientX - cont.x + 16) / cont.width) * 100;
      v = Math.max(0, v);
      v = Math.min(v, 100);
      this.notchPosition = v;
    });
    this.$root.$on("pointerUp", () => {
      if (this.seeking) {
        console.log(this.$refs.audio.duration);
        this.$refs.audio.currentTime =
          (this.notchPosition / 100) * this.$refs.audio.duration;
        this.currentTime =
          (this.$refs.audio.currentTime / this.$refs.audio.duration) * 100;
        console.log(this.$refs.audio.currentTime);
        setTimeout(() => {
          this.seeking = false;
        }, 100);
      }
    });
  },
  methods: {
    transition(p) {
      if (this.seeking) return undefined;
      if (this.playing) return p + " .23s linear";
      return p + " .23s linear";
    },
    play() {
      this.playing ? this.$refs.audio.pause() : this.$refs.audio.play();
    },
    onNotchPressed() {
      console.log("Pressed");
      this.seeking = true;
    },
  },
  computed: {
    progressBar(){
      let value = 0;
      if(this.seeking)value = this.notchPosition;
      else if (this.playing || this.started)value = this.currentTime;
      else value = this.percentage;

      return "width:" + value + "%;";
    }
  }
};
</script>


<style lang="scss" scoped>
.progress-container {
  position: relative;
  height: 10px;
  width: 100%;
  touch-action: none;
}

.playing .progress-notch {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  right: 0;
  width: 2em;
  height: 2em;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}

.progress-notch {
  pointer-events: all;
}

.progress {
  width: 100%;
  background-color: #082e38;
  height: 10px;
  border-radius: 6px;
  will-change: left;
}
.progress-my {
  will-change: transform;
  transform-origin: 0% 50%;
}

.btn-forward {
  margin-top: 2em;
}

.last-sound {
  font-size: 1em;
  margin: 0.5em 0;
}

.my-sound {
  font-weight: 300;
  font-size: 0.75em;
  opacity: 0.66;
  margin-bottom: 1em;
}

.status {
  opacity: 0.66;
}

h2 {
  font-size: 1.2em;
}

.progress-total,
.progress-my {
  height: 100%;
  background-color: #BBDBDD;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px;
}
.progress-my {
  background-color: #66d2f1;
  width: 100%;
}

.buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    &.active {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
      background-color: #fff;
      color: black;
    }
  }
  span {
    width: 100px;
  }
}

.player-cta {
    width: 64px;
    font-size: 12px;
    opacity: 0.76;
    text-align: center;
}

.left {
  margin-right: 2.5em;
}
</style>
