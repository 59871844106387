import Vue from 'vue'
import Vuex from 'vuex'
import ky from '@/network';
import { haversine } from '../harvesine';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: {
      user: null
    },
    loggedIn: false,
    places: [],
    lastLocation: {
      latitude: null,
      longitude: null
    },
    mySound: null,
    latestSound: null,
    soundCount: 0,
    localData: {
      toBeDiscovered: [],
      ackDiscSounds: false,
      ackDiscPlaces: false,
      toAfterLogin: null,
    },
    positionWatchID: null
  },
  mutations: {
    setSession(state,s){
      Vue.set(state,"session",s);
      state.loggedIn = !!s.user;
    },
    setPlaces(state,s){
      s.sort((a,b) => a.name["it"].localeCompare(b.name["it"]));
      state.places = s;
      
    },
    setLastLocation(state,s){
      state.lastLocation = s;
    },
    setSoundData(state,s){
      state.mySound = s.mySound;
      state.latestSound = s.latestSound;
      state.soundCount = s.soundCount;
    },
    addFutureDiscover(state,s){
      if(state.localData.toBeDiscovered.indexOf(s) === -1){
        state.localData.toBeDiscovered.push(s);
      }
      window.localStorage.setItem("local_data",JSON.stringify(state.localData));
    },
    clearFutureDiscover(state){
      state.localData.toBeDiscovered = [];
    },
    setToAfterLogin(state,s){
      if(s === "/login" || s === "/signup" || s === "/welcome") s = "/";
      state.localData.toAfterLogin = s;
      window.localStorage.setItem("local_data",JSON.stringify(state.localData));
    },
    setGLID(state,s){
      state.positionWatchID = s;
    },
    readLocalData(state){
      state.localData = JSON.parse(window.localStorage.getItem("local_data")) || {
        toBeDiscovered: [],
        ackDiscSounds: false,
        ackDiscPlaces: false,
        toAfterLogin: null
      };
    },
    resetLocalData(state){
      state.localData = {
        toBeDiscovered: [],
        ackDiscSounds: false,
        ackDiscPlaces: false,
        toAfterLogin: null
      };
      window.localStorage.setItem("local_data",JSON.stringify(state.localData));
    },
    ackDisclaimerPlaces(state){
      state.localData.ackDiscPlaces = true;
      window.localStorage.setItem("local_data",JSON.stringify(state.localData));
    },
    ackDisclaimerSounds(state){
      state.localData.ackDiscSounds = true;
      window.localStorage.setItem("local_data",JSON.stringify(state.localData));
    },
  },
  actions: {
    async init({state,dispatch,commit}){
      commit("readLocalData");
      await dispatch("updatePlaces");
      await dispatch("getSoundStatus");
      if(state.localData.toBeDiscovered && state.localData.toBeDiscovered.length > 0){
        for(let p of state.localData.toBeDiscovered){
          await dispatch("discover",p);
        }
        commit("clearFutureDiscover");
      }
    },
    async getSession({commit}){
      let s = (await ky.get("./api/Session").json()).session;
      console.log(s)
      commit("setSession",s);
    },
    async refreshSession({commit}){
      let s = (await ky.post("./api/Session/Refresh").json()).session;
      commit("setSession",s);
    },
    async resetPasswordReq(c,emailAddress) {
      return await ky.post("/api/User/PasswordReset",{searchParams: {emailAddress}}).json();
    },
    async resetPassword(c,{token,password,confirm}) {
      return await ky.post("/api/User/ExecuteUserAction/"+token,{json: {
        password,
        confirmPassword: confirm
      }}).json();
    },
    async getSoundStatus({commit}){
      var sData = await ky.get("/api/Sound").json();
      commit("setSoundData",sData);
    },
    async getActivationStatus(c,activationKey){
      return await ky.get('/api/Activation',{searchParams: {activationKey}}).json();
    },
    async login({dispatch},{username,password}){
      await ky.post('/api/User/Login', {headers: {authorization: `Basic ${btoa(username + ":" + password)}`}}).json();
      dispatch("getSession");
      dispatch("init");
    },
    async signup(context,json){
      return await ky.post("/api/User/Signup",{json,timeout: 60 * 1000}).json();
    },
    async discover(context,id){
      var r = (await ky.post("/api/Place/Discover/" + id).json()).place;
      if(context.state.loggedIn){
        context.dispatch("updatePlaces");
      } else {
        context.commit("addFutureDiscover",id);
      }
      return r;
    },
    async startPositionWatch({state,commit,dispatch}){
      if(state.positionWatchID)return;
      let r = window.navigator.geolocation.watchPosition((p) => {
        console.log(p);
        dispatch("updatePlaceDistance",{latitude: p.coords.latitude,longitude: p.coords.longitude});
      })
      commit("setGLID",r);
    },
    async updatePlaces({commit,dispatch,state}){
      var places = await ky.get("/api/Place").json();
      commit("setPlaces",places.places);
      if(state.lastLocation.latitude)dispatch("updatePlaceDistance",state.lastLocation)
    },
    async updatePlaceDistance({state,commit},{latitude,longitude}){
      commit("setLastLocation",{latitude,longitude});
      let places = state.places;
      for(let p of places){
        p.distance = haversine({latitude,longitude},{latitude:p.latitude,longitude:p.longitude});
      }
      commit("setPlaces",places);
    },
    async logout({dispatch,commit}){
      await ky.post("/api/User/Logout").json();
      commit("resetLocalData");
      await dispatch("getSession");
    }
  },
  getters: {
    discoveredPlaces(state){
      return state.places.filter(x => x.discovered);
    },
    undiscoveredPlaces(state){
      return state.places.filter(x => !x.discovered);
    },
    discoveredTours(state){
      return state.places.filter(x => x.discovered && x.type == "tour" && x.outdoorActiveId && !x.autoDiscover);
    },
    undiscoveredTours(state){
      return state.places.filter(x => !x.discovered && x.type == "tour" && x.outdoorActiveId && !x.autoDiscover);
    },
    autodiscoveredTours(state){
      return state.places.filter(x => x.type == "tour" && x.outdoorActiveId && x.autoDiscover);
    },
    discoveredPois(state){
      return state.places.filter(x => x.type == "poi" && x.discovered && x.outdoorActiveId && !x.autoDiscover);
    },
    undiscoveredPois(state){
      return state.places.filter(x => x.type == "poi" && !x.discovered && x.outdoorActiveId && !x.autoDiscover);
    },
    autodiscoveredPois(state){
      return state.places.filter(x => x.type == "poi" && x.outdoorActiveId && x.autoDiscover);
    },
    fountains(state){
      return state.places.filter(x => x.type == "fountain" && x.outdoorActiveId);
    }

  }
})
