<template>
    <div class="flex column align-center text-center">
        <Header :back="true" title="Recupera password" :transparent="true"/>

        <div class="content">

            <p>Inserisci la tua email per iniziare il processo di recupero password</p>
            <input type="email" class="input" placeholder="Indirizzo Email" v-model="email">
            <small v-if="okay">Richiesta inviata, controlla la tua casella E-Mail per recuperare la tua password.</small>
            <div v-else-if="loading">
                <feather type="loader" animation="spin"/>
            </div>
            <button style="margin:1em auto" class="btn btn-forward" @click="passwordReset" v-else>
                <feather type="arrow-right"/>
            </button>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header';
export default {
    components: {
        Header
    },
    data(){
        return {
            loading: false,
            email: null,
            okay: false
        }
    },
    methods: {
        async passwordReset(){
            this.loading = true;
            this.okay = false;
            await this.$store.dispatch("resetPasswordReq",this.email);
            this.loading = false;
            this.okay = true;
        }
    }
}
</script>
<style lang="scss" scoped>
.back{
        position: absolute;
        top: 2em;
        left: 1em;
        .btn {
            width: 36px;
            height: 36px
        }
    }

    .content {
        margin:1em auto;
        text-align: center;
        padding-top: 5em;
    }
</style>
