<template>
  <div id="app" :class="!this.$route.name ? '' : 'route-'+this.$route.name.toLowerCase().replace(' ','_')" @pointermove="onNotchMoved" @pointerup="onNotchDepressed" @mousemove="onNotchMoved" :key="$route.fullPath">
    <SideBar v-if="$store.state.loggedIn" />
    <div
      class="router-wrap"
      :class="{
        sidebarOpened: $root.sidebarOpened && $store.state.session && $store.state.loggedIn
      }"
    >
      <div id="clickarea" @click="changeRoute('/sound')"></div>
      <router-view id="router" />
    </div>
    <div id="bg-details"></div>
    <Popup />
  </div>
</template>

<script>
import SideBar from "./components/SideBar";
import Popup from "./components/Dialog";
export default {
  components: {
    SideBar,
    Popup
  },
  methods: {
    onNotchDepressed() {
      this.$root.$emit("pointerUp");
    },
    changeRoute() {
      if (!this.$root.sidebarOpened) return;
      // this.$router.push(r);
      setTimeout(() => {
        this.$root.sidebarOpened = false;
      }, 0);
    },
    onNotchMoved(e) {
      this.$root.$emit("pointerMove", e);
    }
  }
};
</script>
<style lang="scss">
#app {
  height: 100%;
  overflow: hidden;
}

#router {
  padding: 0 1em;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  // height: -webkit-fill-available;
  // overflow-y: auto;
  position: relative;
  z-index: 1;
}

.router-wrap {
  height: 100%;
}

.router-wrap {
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.2s;
}

#clickarea {
  display: none;
}

.sidebarOpened {
  transform: translate(80%) scale(0.9);
  opacity: 0.8;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  #clickarea {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  #router {
    pointer-events: none;
  }
}
</style>
