<template>
  <div class="map">
    <div v-if="showAltitude" id="oax-api-elpro" style="width: 100%"></div>
    <div v-else id="map_canvas" :style="'min-height:' + height + 'px'"></div>
  </div>
</template>

<script>
export default {
  props: ["id", "showAltitude",'height'],
  methods: {
    onInitMap() {
      var mapDiv = document.getElementById("map_canvas");
      var mapTypeIds = ["oa_map", "oa_topo", "oa_map_winter"];
      var mapConfig = {
        bounds: this.layer.getBounds(),
        mapTypeId: "oa_map",
        mapTypeControlOptions: { mapTypeIds },
      };
      var map = this.oamaps.map(mapDiv, mapConfig);
      // add GeomLayer to map
      this.layer.setMap(map);
    },
    initOAX(oamaps) {
      this.oamaps = oamaps;
      let layerConfig = {
        markersActive: true,
        defaultIW: true,
      };
      // initialize GeomLayer
      this.layer = new this.oamaps.GeomLayer(this.id, layerConfig);
      this.layer.whenLoaded(this.onInitMap);
    },
  },
  mounted() {
    window.oa.api.maps(this.initOAX);
  },
};
</script>

<style lang="scss" scoped>
.map {
    width: 100vw;
    height: 100%;
  margin-left: -1em;
}
#map_canvas {
  margin: 0 auto;
  height:100%;
  width: 100%;
}
</style>
