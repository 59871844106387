<template>
    <div class="card" @click="showDetailsPage">
        <div>
            <div class="img-cover" v-if="primaryImg" :style="{'background-img': primaryImg}">
                <div v-if="difficulty" class="badge" :class="'diff-badge ' + difficulty">
                    {{$t("difficulty."+difficulty)}}
                </div>
                <img :src="primaryImg"/>
            </div>
            <div class="flex justify-center align-center text-center" style="padding:1em">
                <div>
                    <span class="notranslate" id="where" >{{descr}}</span>
                    <h3   class="notranslate" style="font-size:1.1em;margin:0; text-transform:uppercase">{{fonte.name[$i18n.locale] || fonte.name["it"]}}</h3>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['fonte'],
    methods: {
        formatKM(distanza){
            if(distanza < 1)return Math.round(distanza * 1000) + " Metri";
            else return distanza.toFixed(1) + " Km";
        },
        showDetailsPage(){
            this.$router.push(`/sources/details/${this.fonte.id}`);
        }
    },
    computed: {
        descr(){
            let descr = this.fonte.description;
            if(descr && descr[this.$i18n.locale])return descr[this.$i18n.locale];
            let oData = this.fonte.outdoorActiveData[this.$i18n.locale];
            if(oData.address && oData.address.town)return oData.address.town;
            if(oData.friendly_location)return oData.friendly_location;
            return "";
        },
        difficulty(){
            let data = this.fonte.outdoorActiveData[this.$i18n.locale] || this.fonte.outdoorActiveData["en"]
            if(!data || !data.rating)return null;
            let diff =  data.rating.difficulty;
            if(!diff)return null;
            return ["unknown","easy","medium","hard"][diff];
        },
        primaryImg(){
            let data = this.fonte.outdoorActiveData[this.$i18n.locale] || this.fonte.outdoorActiveData["en"]
            if(!data)return null;
            let diff = data.primaryImage;
            if(!diff)return null;
            return 'https://img.oastatic.com/img/' + diff.id + '/.jpg';
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/style/variables.scss";
    .card{
        background-color: rgba(82,173,195, .8);
        border-radius: 8px;
        margin: .5em auto;
        // padding: 1em;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
    }

    .diff-badge {
        padding: 4px 8px;
        position: absolute;
        font-size: 12px;
        top: 1em;
        right: 1em;
        border-radius: 8px;
        &.easy {
            background-color: #0066cc;
        }
        &.medium {
            background-color: #cc0000;
        }
        &.hard {
            background-color: #000000;
        }
    }

    #where {
        opacity: .8;
        font-weight: 600;
    }

    .img-cover img {
        display: block;
        width: 100%;
        height: 180px;
        object-fit: cover;
    }

</style>
