<template>
  <div class="topbar" :class="{transparent: transparent && !scrolled}">
    <div id="sponsors">
      <img src="../assets/valdisole.svg" />
      <img src="../assets/trentino.svg" />
    </div>
    <div class="row" :class="{'noBtn':noBtn, 'difficulty': difficulty}">

      <div>
        <template v-if="!noBtn">
        <router-link v-if="typeof back == 'string'" class="btn btn-back" :to="back"><i class="fas fa-arrow-left"></i
            ></router-link>
        <a v-else-if="back" class="btn btn-back" @click="$router.go(-1)"
        ><i class="fas fa-arrow-left"></i
            ></a>
            <a v-else @click="openMenu" id="menu-btn"
        ><img src="@/assets/menu.svg" height="20px"
            /></a></template>
      </div>
    <div class="text-center">
      <div :class="{marquee: useMarquee}" ref="titleMarq"><h3 ref="titleHead" v-if="title">{{title}}</h3></div>
      <p class="label" style="margin:0 auto" v-if="desc">{{desc}}</p>

      <slot></slot>

    </div>
    <div/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["back", "title", "desc", "transparent", "marquee", "noBtn", "difficulty"],
  data () {
    return {
      scrolled: false,
      useMarquee: this.marquee
    }
  },

  methods: {
   
    openMenu() {
      this.$root.sidebarOpened = !this.$root.sidebarOpened
      console.log(this.$store.state.loggedIn)
    },
    handleScroll (e) {
      if(e.target.scrollTop > 0) this.scrolled = true
      else this.scrolled = false
    }
  },
  mounted () {
    if(this.$refs.titleHead && this.$refs.titleMarq) {
      if(this.$refs.titleHead.offsetWidth < this.$refs.titleMarq.offsetWidth) this.useMarquee = false;
    }
    let sb = document.querySelector('.body');
    if(!sb)return;
    sb.addEventListener('scroll', this.handleScroll);


  },
  destroyed () {
    let sb = document.querySelector('.body');
    if(!sb)return;
    sb.removeEventListener('scroll', this.handleScroll);
  },

};
</script>

<style lang="scss">
.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  z-index: 9999;
  transition: .3s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-backdrop-filter: blur(20px) saturate(1.2);
  // backdrop-filter: blur(20px) saturate(1.2); Forse un giorno Chrome avrà performance decenti coi filter
  background: transparentize($color: #368ca4, $amount: 0.1);

  &.transparent {
    background: none;
    -webkit-backdrop-filter: none;
  }

  #menu-btn {
    display: block;
  }

  .btn-back {
    height: 36px;
    width: 36px;
  }
}

.sidebarOpened .topbar {
  pointer-events: auto;
}

.row {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  grid-gap: 0 1em;
  align-items: center;
  &.difficulty {
    grid-template-columns: 1fr 6fr 60px;
  }
  h3 {
    margin: 0;
  }
  &.noBtn {
    display: block;
  }
}

#sponsors {
  width: 100%;
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 999;
  * {
    height: 1em;
  }
}
</style>
