<template>
  <div class="body" :class="{video: $root.showTeaser}">
  <Header :noBtn="true" :transparent="true"/>
  <div class="grid_container">
    <!--div class="bottle"></div-->
    <div class="content">
      <p>
        <b>Uno Di Un Milione</b> è un’opera relazionale dedicata a tutti coloro che considerano la tutela delle risorse ambientali il caposaldo su cui costruire una visione al futuro da condividere con le nuove generazioni.
      </p>
      <p>
        Le opere d’arte e i meccanismi di condivisione verranno svelati grazie alla borraccia <b>Uno Di Un Milione.</b> Entra a far parte della community, suona la tua nota, trova la tua fonte.
      </p>
        <div class="buttons">
          <a href="https://unodiunmilione.com/pages/project/" target="_blank" class="btn btn-large">Scopri di più</a>
          <a href="https://unodiunmilione.com/punti-vendita-val-di-sole/" target="_blank" class="btn btn-large">Dove Acquistare la Borraccia</a>
        </div>
        <small>Sei già Uno di Un Milione? <router-link :to="'/login'">{{$t("welcome.btnLogin")}}</router-link></small>
    </div>
  </div>

  <CustomVideo @hide="$root.showTeaser = false" v-if="$root.showTeaser" poster="/img/video_poster.png" :url="'https://udum-storage.fra1.digitaloceanspaces.com/teaser/Trailer.mov_TLM.m3u8'" :fullscreen="true" :canHide="true"/>
  </div>
</template>

<script>
import CustomVideo from '../components/CustomVideo.vue'
import Header from '../components/Header'
export default {
  components:{
    CustomVideo,
    Header
  }
}
</script>


<style lang="scss" scoped>
  .content{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 1em;
    box-sizing: border-box;

  }
  .buttons{
    margin: 2em 0;
    & > * {
      padding: 1.2em 3em;
    }
  }
  a.btn{
    margin: 1em;
    text-decoration: none;
    display: block;
  }



  .bottle{
    background: url(/img/bottle-with-gradient.png) center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: bottom center;
    margin: 0 auto;
    margin-top: 2em;
  }

  .grid_container {
    display: grid;
    padding-top: 4em;
    box-sizing: border-box;
    align-items: center;
    grid-template-rows: 2fr 1.5fr;
    min-height: 100%;
    height: 100%;
  }

  #sponsors {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 1em;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 9999;
  * {
    height: 1em;
  }
}

</style>
