<template>
  <div class="body">
    <Header :transparent="true" title="Ascolta la tua Nota" />
    <div class="grid_container disclaimer" v-if="!$store.state.localData.ackDiscSounds && showDisclaimer" >
         <div class="bottle"></div>
        <div class="flex column align-center" style="z-index: 9">
          <p style="margin-bottom:0" class="disclaimer">
            <b>Scopri la tua nota</b>, entra a far parte della community <b>Uno di Un Milione</b> e contribuisci a svelare l’intero brano sinfonico.
            <br>
            Accedi alla sezione "Ascolta la tua Nota" per sentire come si sta componendo il <b>brano</b> grazie alle nuove note arrivate dopo la tua!
          </p>
          <p style="margin-top:0" class="text-center">
            <b>TU, una nota</b>
          </p>
          <div class="flex align-center">
            <div class="row">
              <feather type="headphones" size="34"/>&nbsp;<small>Indossa le cuffie per immergerti nell’esperienza sonora</small>
            </div>
          </div>
          <br>
          <label style="align-self: flex-start;" class="mini-label">
              <input type="checkbox" v-model="doNotAskAgain">&nbsp;Non chiedere più
          </label>
          <br>
          <button class="btn btn-forward" @click="next">
            <feather type="arrow-right"/>
          </button>
        </div>

    </div>
    <div class="pg_c"  v-else>

        <div class="bottle"></div>

      <div>
        <span class="st">HAI SBLOCCATO LA TUA NOTA!</span>
          <br>
          <span class="st" style="opacity: 0.75;">UNO DI UN MILIONE</span>
        <AudioPlayer :url="'/api/Sound/DownloadMyNote'" :title="$t('sound.name', { name: my.discoverName })" :cta="$t('sound.listenNote')"/>
        <AudioPlayer
          :url="'/api/Sound/DownloadTotal'"
          :percentage="totalPercentage"
          :title="$t('sound.percentage')"
          :cta="$t('sound.listenTrack')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import AudioPlayer from "../components/AudioPlayer";

export default {
  components: {
    Header,
    AudioPlayer,
  },
  data(){
    return {
      showDisclaimer: true,
      doNotAskAgain: false
    }
  },
  methods: {
    next(){
      this.showDisclaimer = false;
      if(this.doNotAskAgain){
        this.$store.commit("ackDisclaimerSounds");
      }
    }
  },
  computed: {
    totalPercentage() {
      return this.$store.state.latestSound.soundPosition /3500 * 100;
    },
    my() {
      return this.$store.state.mySound;
    },
    percentageMy() {
      return (this.my.soundPosition / this.$store.state.soundCount) * 100;
    },
    latest() {
      return this.$store.state.latestSound;
    },
  },
};
</script>

<style lang="scss" scoped>

.body {
  box-sizing: border-box;
  padding-top: 4em !important;
}




.content {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  margin: 0 auto;
 /* @media screen and (max-height: 560px) {
    position: absolute;
    top: calc(2em + 50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }*/
}

.st {
    font-weight: 100;
}

.disclaimer{
  padding: 2em 0;
  font-size: 14px;
  line-height: 1.5;

}


  .bottle{
    background: url(/img/bottle-with-gradient.png) center no-repeat;
    background-size: auto 88%;
    width: 100%;
    height: 100%;
    background-position: center;
    margin: 0 auto;
    margin-top: 4em;
    @media screen and (max-height: 560px) {
      display: none;
    }
  }

  .disclaimer .bottle {
    background-size: contain;
  }

  .grid_container.disclaimer, .pg_c {
    display: grid;
    box-sizing: border-box;
    align-items: center;
    grid-template-rows: 2fr 1.5fr;
    min-height: 100%;
    height: 100%;
    padding-bottom: 2em;
  }

  .pg_c {
    padding-bottom: 2em;
    grid-gap:1em;
    @media screen and (max-height: 560px) {
      grid-template-rows: 1fr;
    }
  }

</style>
