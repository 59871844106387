<template>
    <div class="flex column align-center">
        <Header :back="true" :title="$t('login.title')" :transparent="true"/>
        <div class="content flex column align-center">
        <p>{{$t('login.subtitle')}}</p>
        <form @submit.prevent="login" class="flex column align-center" style="width:100%">
            <input class="input" type="email" :placeholder="$t('login.email')" v-model="username" required>
            <input class="input" type="password" :placeholder="$t('login.password')"  v-model="password" required>
            <br>
            <small><router-link :to="'/resetPassword'" style="align-self: flex-start;">{{$t('login.forgot')}}</router-link></small>
            <div class="error" v-if="error">{{error}}</div>
            <br>
            <feather type="loader" v-if="loading" animation="spin"/>
            <input type="submit" class="btn btn-lg" value="Entra" v-else>
        </form>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
export default {
  components: { Header },
    data(){
        return {
            username: '',
            password: '',
            loading: false,
            error: null
        }
    },
    methods: {
        async login(){
            try{
                this.loading = true;
                await this.$store.dispatch("login",{username:this.username,password:this.password});
                if(this.$store.state.localData.toAfterLogin && this.$store.state.localData.toAfterLogin !== "/"){
                    this.$router.replace(this.$store.state.localData.toAfterLogin);
                    this.$store.commit("setToAfterLogin","/");
                }
                else this.$router.replace("/sound")
            } catch(e){
                switch(e.response.status){
                    case 400:
                        this.error = "Utente o password errata";
                        break;
                    default:
                        this.error = "Errore Interno del Server (" + e.response.status + ")";
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .main-cnt{
        padding-top: 1em;
        margin: 0 auto;
    }


     .back{
        position: absolute;
        top: 2em;
        left: 1em;
        .btn {
            width: 36px;
            height: 36px
        }
    }

    .content{
        width: 100%;
        margin: 1em auto;
        padding-top: 6em;
    }


    .btn-forward{
        margin-top: 2em;
    }
</style>
