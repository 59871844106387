<template>
    <h1 v-if="loading">Stiamo Scoprendo la fonte</h1>
    <div class="discover main flex column justify-between" v-else-if="discovered && discovered.type !== 'fountain' && $store.state.loggedIn" style="padding-top: 4em;">
        <Header :noBtn="true">
                <h2 style="margin:0 auto"  v-if="discovered.type == 'tour'">Nuovo itinerario scoperto</h2>
                <h2 style="margin:0 auto" v-else>Nuova meta scoperta</h2>
                <p style="margin:0 auto;text-transform:uppercase;font-size:.8em">Hai sbloccato il contenuto speciale di<br><span class="label" style="font-size:1.3em">{{discovered.name[this.$i18n.locale] || discovered.name["it"]}}</span></p>
        </Header>
        <div class="content text-center" style="padding-top:73px">
            <component :is="componentData" v-if="componentData"/>
                <div v-if="discovered.thanksTo" class="thanks-to" ref="thanks"  v-html="discovered.thanksTo">
                </div>
            <button style="margin: 1em auto" class="btn" @click="$router.push('/places')">{{discovered.type === 'poi' ? 'Scopri altre mete' : 'Scopri altri itinerari'}}</button>
        </div>
    </div>
    <div v-else-if="discovered && discovered.type !== 'fountain'" class="discover main flex column justify-between" style="padding-top: 4em;">
        <Header :noBtn="true" :transparent="true"/>
        <div class="bottle" style="height:200px"></div>
        <div class="flex column justify-between" style="flex-grow: 1;">
            <div class="flex column align-center">
            <h1>Diventa anche <b>TU</b><br>Uno Di Un Milione</h1>
            <small style="text-align: center;">Acquista la borraccia per entrare nell'esperienza</small>
            <br>
            <a  @click="goToWelcome" class="btn">Scopri il progetto</a>
            <br>
            <small>Sei già Uno di Un Milione? <a @click="goToLogin">{{$t("welcome.btnLogin")}}</a></small>
        </div>
        </div>
    </div>
    <div v-else-if="discovered && discovered.type === 'fountain'" class="discover main" style="padding-top: 4em;">

        <Header :noBtn="true">
                <span id="where" >{{descr}}</span>
                <h3 style="font-size:1.1em;margin:0; text-transform:uppercase">{{discovered.name[$i18n.locale] || discovered.name["it"]}}</h3>
        </Header>

        <div class="content flex column" style="padding-top:0">
            <OOIDetails style="padding-top:78px" :poiDetails="poiDetails" :place="discovered"/>
            <div style="margin:1em auto;margin-top:2em" class=" flex column justify-between" v-if="!$store.state.loggedIn">
                <div class="flex column align-center">
                    <div class="bottle"></div>
                <h1>Diventa anche <b>TU</b><br>Uno Di Un Milione</h1>
                <small style="text-align: center;">Acquista la borraccia per entrare nell'esperienza</small>
                <br>
                <a @click="goToWelcome" class="btn">Scopri il progetto</a>
                <br>
                <small>Sei già Uno di Un Milione? <a @click="goToLogin">{{$t("welcome.btnLogin")}}</a></small>
            </div>
            </div>
            <button  style="margin: 1em auto"  v-else class="btn" @click="$router.push('/places?noDisclaimer=true')">Scopri altre fontane</button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import OOIDetails from '../components/OOIDetails.vue'
import Header from '../components/Header.vue';
export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        OOIDetails,
        Header
    },
    data(){
        return {
            loading: false,
            discovered:null,
            componentData: null
        }
    },
    async created(){
        if(this.$route.params.id){
            this.loading = true;
            this.discovered = await this.$store.dispatch("discover",this.$route.params.id)
            if (this.discovered && this.discovered.discoverData) {
                console.log("Logged in " + this.$store.state.loggedIn);
                let dd = this.discovered.discoverData[this.$i18n.locale] || this.discovered.discoverData["it"];
                this.componentData = Vue.compile(dd);
                setTimeout(() => {
                    this.$refs.thanks.querySelector('.body').classList.remove('body')
                },0)
            }
            this.loading = false;

        }
    },


    methods: {
        goToWelcome(){
            this.$store.commit("setToAfterLogin","/discover/"+this.$route.params.id);
            this.$router.push("/welcome/discover");
        },
        goToLogin(){
            this.$store.commit("setToAfterLogin","/discover/"+this.$route.params.id);
            this.$router.push("/login");
        }
    },
    computed: {
        poiDetails(){
            return this.discovered.outdoorActiveData[this.$i18n.locale] || this.discovered.outdoorActiveData.en;
        },
        descr(){
            let descr = this.discovered.description;
            if(descr && descr[this.$i18n.locale])return descr[this.$i18n.locale];
            let oData = this.discovered.outdoorActiveData[this.$i18n.locale];
            if(oData.address && oData.address.town)return oData.address.town;
            if(oData.friendly_location)return oData.friendly_location;
            return "";
        },
    }
}
</script>

<style lang="scss" scoped>
    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    h1 {
        text-align: center;
    }

    .content {
        padding-top: 5em;
    }


.bottle {
  background: url(/img/bottle-with-gradient.png) center no-repeat;
  background-size: contain;
  width: 200px;
  height: 100px;
  background-repeat: no-repeat;
  margin: 0 auto;
}




.cta {
    opacity: 0.85;
}
</style>

<style lang="scss">

.thanks-to img {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: contain;
    margin: 0 auto;
}

#thanks-to-signature {
    text-align: center;
    text-transform: uppercase;
    margin-top: 2em;
}

#thanks-to-body, #text-content {
    font-size: 16px;
    text-align: justify;
}

#thanks-to-label{
    text-align: center;
    color: white;
    font-size: 1.2em;

}

#thanks-to-signature .role {
    font-style: italic;
    text-transform: none;
    opacity: 0.8;
    display: block;
}
.discover .tab-header {
    top: 100px!important;
    z-index: 9999!important;
}

.discover #map_canvas {
  margin: 0 auto;
  min-height: 220px!important;

}

.discover {

    .content {
        padding-bottom:7em;
        box-sizing: border-box;
    }

}
</style>
