var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content flex align-end",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"left flex column align-start",staticStyle:{"flex-grow":"1"}},[_c('h2',[_vm._v(_vm._s(_vm.title || 'Contenuto Speciale'))]),_c('div',{ref:"progressContainer",staticClass:"progress-container",class:{ playing: _vm.playing || _vm.started }},[_c('div',{staticClass:"progress"}),_c('div',{staticClass:"progress-total",style:({
          width: _vm.percentage + '%',
          transition: _vm.transition('width'),
        })}),_c('div',{staticClass:"progress-my",style:(_vm.progressBar)}),_c('div',{staticClass:"progress-notch",style:({
          left: 'calc(' + _vm.notchPosition + '%' + ' - 20px)',
          transition: _vm.transition('left'),
        }),on:{"pointerdown":_vm.onNotchPressed}})]),(_vm.percentage)?_c('div',{staticStyle:{"position":"relative","width":"100%"}},[_c('div',{style:({
          'left': 'calc(' +_vm.percentage + '% - 18px)',
          'padding-top': '12px',
          'position': 'absolute'
        })},[_vm._v(" "+_vm._s(_vm.percentage.toFixed())+"% ")])]):_vm._e()]),_c('div',{staticClass:"flex column align-center justify-center right"},[_c('div',{staticClass:"btn btn-forward",on:{"click":_vm.play}},[(_vm.buffering)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas",class:{ 'fa-play': !_vm.playing, 'fa-pause': _vm.playing }})]),(_vm.cta)?_c('span',{staticClass:"player-cta"},[_vm._v(_vm._s(_vm.cta))]):_vm._e()]),_c('audio',{ref:"audio",attrs:{"preload":"none","src":_vm.url}})])}
var staticRenderFns = []

export { render, staticRenderFns }