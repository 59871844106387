<template>
  <div class="container">
    <Header title="I nostri partner"/>

    <div class="partners" style="padding-bottom:0!important">
      <img src="/partners/popack.svg" id="popack">
      <img src="/partners/collettivo.svg" id="collettivo">
    </div>

    <p align="center" class="label">Ringraziano</p>

    <div class="partners">
      <img v-for="(p,i) in partners" :src="'/partners/'+p" :id="p.replace('.svg','').replace('.png','').replace('.jpg','')" :key="i">
    </div>

  </div>
</template>
<script>
import Header from '../components/Header'
export default {
  components: {
    Header
  },

  data() {
    return {
      partners: [
        'valdisole.svg',
        'trentino.png',
        'stelvio.png',
        'pejo.svg',
        'comune-peio.png',
        'scala.jpg',
        'bmw.svg',
        'rai-colored.png',
        'karray.svg',
        'muse.svg',
        'comunita-valdisole.png',
        'provincia-trento.png',
        'eccher.jpg',        
        'scuolagallo.png',
        'appm.png',
        'caritro.png',
        'peroni.svg',
        'swarovski.svg',
        'tsm.png',
      ]
    }
  },
}
</script>
<style lang="scss">
  .route-partner .router-wrap {
    background-image: url('/img/fiumi.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
</style>
<style lang="scss" scoped>
@import '@/style/variables.scss';

  .container {
    padding-top: 8em!important;
    padding-bottom: 3em!important;
    box-sizing: border-box;
  }
  
  .label {
    color: $color-accent;
    margin: 2em auto;
  }


  .partners {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    align-items: center;
    position: relative;
    grid-gap: 3em 1em;
    text-align: center;

    img {
      width: 50%;
      min-width: 100px;
      object-fit: contain;
      margin: .5em auto;
    }

    #collettivo {
      height: 90px;
    }

    #scala {
      height: 50px;
    }

    #stelvio {
      grid-row: 2 / 4;
      grid-column: 1;
      height: 200px;
    }

    #comune-peio {
      height: 90px;
    }

    #comunita-valdisole {
      height: 90px;
    }

    #provincia-trento {
      height: 120px
    }

    #appm {
      width: 130px
    }

    #tsm {
      grid-column: 1 / 3;
      height: 50px;
    }

  }
  

</style>
